import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import CustomError from "../../components/Error/CustomError";
import { Helmet } from "react-helmet-async";
import { JobDescriptionService } from "../../services/dataService";
import { toast } from "react-toastify";

const Jobdetails = () => {
  const location = useLocation();
  const rowData = location.state?.rowData;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [jobDetailsData, setJobDetailsData] = useState();
  const [isJobApplied, setIsJobApplied] = useState(false);
  const [isHaveResume, setIsHaveResume] = useState(false);
  const [error, setError] = useState(null);
  const jobId = rowData?.job_id;

  const handleBack = () => {
    navigate("/jobs/opening");
  };

  const handleContinueApply = (job_id) => {
    navigate("/jobs/descriptions", { state: { rowData: { job_id } } });
  };

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        setLoading(true);
        const response = await JobDescriptionService.jobDescription(jobId);
        setIsJobApplied(response?.data?.is_applied_successfully);
        setIsHaveResume(response?.data?.is_resume);
        setJobDetailsData(response?.data?.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchJobDetails();
  }, [jobId]);

  const details = [
    ["Company Name", jobDetailsData?.company],
    ["Job Role", jobDetailsData?.job_role],
    ["Number Of Openings", jobDetailsData?.no_of_post],
    ["Shifting Time", jobDetailsData?.shifting_time],
    ["Job Location", jobDetailsData?.location],
    ["Qualification", jobDetailsData?.qualification],
    ["10th", jobDetailsData?.["10th"] + "%"],
    ["12th", jobDetailsData?.["12th"] + "%"],
    ["Graduation", jobDetailsData?.Graduation + "CGPA"],
    ["Year of Passing", jobDetailsData?.year_of_passing],
    ["Salary", jobDetailsData?.Package],
  ];

  const skills = JSON.parse(jobDetailsData?.skill_required || "[]");

  return (
    <div className="flex justify-center items-center min-h-screen p-4">
      <Helmet>
        <title>EduSkills | Jobs Description</title>
      </Helmet>
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <Oval
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : error ? (
        <CustomError message={error} />
      ) : (
        <div className="bg-sky-200 shadow-lg rounded-lg p-4 mx-auto max-w-6xl w-full">
          {jobDetailsData && (
            <div className="flex flex-col w-full">
              <div className="w-full p-4">
                <h3 className="text-xl font-semibold text-center mb-4">
                  {jobDetailsData.job_title}
                </h3>
                {details.map(([label, value]) => (
                  <div
                    key={label}
                    className="flex flex-col md:flex-row justify-center items-center mb-2 w-full"
                  >
                    <div className="w-full md:w-1/6">
                      <p className="text-sm font-semibold mt-2">{label}:</p>
                    </div>
                    <div className="w-full md:w-1/2">
                      <p className="text-sm bg-white border-[1px] border-gray-300 shadow-custom-blue rounded-md p-5 mt-2">
                        {value}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full p-2">
                <div className="flex flex-col md:flex-row justify-center">
                  <h3 className="text-sm w-full md:w-1/6 font-semibold mt-5 text-gray-800">
                    Selection Process:
                  </h3>
                  <ol className="text-sm mt-2 border shadow-custom-blue bg-white w-full md:w-1/2 list-disc p-5">
                    <li>{jobDetailsData?.selection_process}</li>
                  </ol>
                </div>
                <div className="flex flex-col md:flex-row mt-8 justify-center">
                  <h3 className="text-sm w-full md:w-1/6 font-semibold text-gray-800">
                    Skills:
                  </h3>
                  <ul className="list-disc w-full md:w-1/2 border shadow-custom-blue bg-white p-5">
                    {skills.map((skill, index) => (
                      <li key={index} className="text-sm mt-2">
                        {skill}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="flex flex-col md:flex-row justify-center items-center mb-2 w-full mt-8">
                  <div className="w-full md:w-1/6">
                    <p className="text-sm font-semibold mt-2">
                      Registration Closes on:
                    </p>
                  </div>
                  <div className="w-full md:w-1/2">
                    <p className="text-sm bg-white border-[1px] border-gray-300 shadow-custom-blue rounded-md p-5 mt-2 font-semibold text-red-500">
                      {new Date(jobDetailsData.reg_end).toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col md:flex-row justify-between gap-5 mt-5 font-semibold tracking-wide pb-8 pr-8 ml-8">
            <button
              className="bg-red-500 px-2 py-2 md:px-4 md:py-2 rounded-md shadow-md text-white"
              onClick={handleBack}
            >
              {isJobApplied ? "Go Back" : "Not Interested"}
            </button>
            {isJobApplied === false && jobDetailsData?.status === "Publish" ? (
              <button
                className="bg-blue-500 px-2 py-1 md:px-4 md:py-2 rounded-md shadow-md text-white hover:scale-105"
                onClick={() => handleContinueApply(jobDetailsData?.job_id)}
              >
                APPLY
              </button>
            ) : (
              <button
                className="bg-sky-400 px-2 py-1 md:px-4 md:py-2 rounded-md shadow-md text-white w-auto"
                disabled
              >
                {isJobApplied ? "Already applied" : "Application closed"}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Jobdetails;
