import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getInternshipCertificateDetails } from "../../services/dataService";
import { CircleX, CrossIcon, XCircle } from "lucide-react";
import { CloudUpload, CheckCircle, Verified } from "@mui/icons-material";

const FileUpload = () => {
  const [formData, setFormData] = useState({});
  const [selectedCourses, setSelectedCourses] = useState({});
  const [courseData, setCourseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response =
          await getInternshipCertificateDetails.certificateDetails();
        console.log(response);
        setCourseData(response?.data);
      } catch (error) {
        console.error("Error fetching course data:", error);
        setError(error);
        toast.error("Failed to fetch course data");
      } finally {
        setLoading(false);
      }
    };

    fetchCourseData();
  }, []);

  const handleCheckboxChange = (course_id) => {
    setSelectedCourses((prevSelected) => ({
      ...prevSelected,
      [course_id]: !prevSelected[course_id],
    }));
  };

  const handleFileChange = (course_id, event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFormData((prev) => ({
        ...prev,
        [course_id]: {
          ...prev[course_id],
          file: selectedFile,
        },
      }));
    } else {
      toast.error("Please select a PDF file");
    }
  };

  const handleTextChange = (course_id, event) => {
    const inputValue = event.target.value;
    setFormData((prev) => ({
      ...prev,
      [course_id]: {
        ...prev[course_id],
        text: inputValue,
      },
    }));
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   if (!Object.values(selectedCourses).some((isSelected) => isSelected)) {
  //     toast.error("Please select at least one course.");
  //     return;
  //   }

  //   const courseDataArray = [];

  //   // Construct array of course objects based on the selected courses and inputs
  //   Object.keys(selectedCourses).forEach((course_id) => {
  //     if (selectedCourses[course_id]) {
  //       const course = formData[course_id];
  //       const courseDetails = courseData?.courses.find(
  //         (c) => c.course_id === Number(course_id)
  //       );

  //       if (!courseDetails) {
  //         toast.error(`Course details not found for course ID ${course_id}`);
  //         return;
  //       }

  //       if (courseData.status === 0 && !course?.file) {
  //         toast.error(`Please upload a PDF for ${courseDetails.course_name}`);
  //         return;
  //       }

  //       if (courseData.status === 1 && !course?.text) {
  //         toast.error(
  //           `Please enter a valid URL for ${courseDetails.course_name}`
  //         );
  //         return;
  //       }

  //       // Prepare each course object with its respective data
  //       const courseObject = {
  //         course_name: courseDetails.course_name,
  //         domain_id: courseDetails.domain_id,
  //         course_id: courseDetails.course_id,
  //         text: course?.text || null,
  //         file: course?.file || null, // Add file as a simple property
  //       };

  //       courseDataArray.push(courseObject);
  //     }
  //   });

  //   // Log the data (without files)
  //   //console.log({ data: courseDataArray });

  //   try {
  //     setUploading(true);

  //     const response = await axios.post(
  //       "http://192.168.1.2:8000/domain/course_certificate_upload",
  //       { data: courseDataArray }, // Directly sending the array of objects
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     toast.success(response.data.detail);
  //   } catch (error) {
  //     toast.error("Failed to submit the form");
  //   } finally {
  //     setUploading(false);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const selectedCoursesWithStatus0 = Object.keys(selectedCourses).filter(
      (course_id) =>
        selectedCourses[course_id] &&
        courseData?.courses.find((c) => c.course_id === Number(course_id))
          ?.status === "0"
    );

    if (selectedCoursesWithStatus0.length === 0) {
      toast.error("Please select at least one course to submit.");
      return;
    }

    const courseDataArray = [];

    const encodeFileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result.split(",")[1]); // Get the base64 part
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };

    for (const course_id of Object.keys(selectedCourses)) {
      if (selectedCourses[course_id]) {
        const course = formData[course_id];
        const courseDetails = courseData?.courses.find(
          (c) => c.course_id === Number(course_id)
        );

        if (!courseDetails) {
          toast.error(`Course details not found for course ID ${course_id}`);
          return;
        }

        if (courseData.status === 0 && !course?.file) {
          toast.error(`Please upload a PDF for ${courseDetails.course_name}`);
          return;
        }

        if (courseData.status === 1 && !course?.text) {
          toast.error(
            `Please enter a valid URL for ${courseDetails.course_name}`
          );
          return;
        }

        const courseObject = {
          course_name: courseDetails.course_name,
          domain_id: courseDetails.domain_id,
          course_id: courseDetails.course_id,
          text: course?.text || null,
          file: null,
        };

        // If there's a file, convert it to base64
        if (course?.file) {
          const base64File = await encodeFileToBase64(course.file);
          courseObject.file = base64File;
        }

        courseDataArray.push(courseObject);
      }
    }

    // Log the data to see what will be sent
    console.log(
      "Form Data to Submit:",
      JSON.stringify({ data: courseDataArray }, null, 2)
    );

    try {
      setUploading(true);

      // Send the data as JSON, including base64 file content
      await axios.post(
        "https://erpapi.eduskillsfoundation.org/domain/course_certificate_upload",
        { data: courseDataArray },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Submission successful");
      if (courseDataArray.length > 0) {
        window.location.reload();
      }
    } catch (error) {
      toast.error("Failed to submit the form");
    } finally {
      setUploading(false);
    }
  };

  // if (courseData?.status === 0) {
  //   return (
  //     <div className="flex items-center justify-center px-8 py-8 bg-gray-100">
  //       <div className="max-w-sm w-full bg-white shadow-lg rounded-lg p-6 text-center">
  //         <h1 className="text-2xl font-bold text-gray-800 mb-4">
  //            Will Open Soon!
  //         </h1>
  //         <p className="text-gray-600 mb-4">
  //           Stay tuned for updates and be ready to join us!
  //         </p>
  //       </div>
  //     </div>
  //   );
  // }

  if (error) {
    return <ErrorComponent error={error} />; // Your custom error component
  }

  if (
    Array.isArray(courseData) &&
    courseData.length > 0 &&
    courseData[0] ===
      "You have already submitted all the Links and Certificates"
  ) {
    return (
      <div className="flex items-center justify-center px-8 py-8 bg-gray-100">
        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg p-6 text-center">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">
            You have already submitted all the Links and Certificates
          </h1>
        </div>
      </div>
    );
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f0f0",
        padding: 2,
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Card
          sx={{
            width: "100%",
            maxWidth: { xs: "100%", sm: "600px", md: "900px" },
            padding: { xs: 2, sm: 3 },
            boxShadow: 5,
            borderRadius: 3,
            backgroundColor: "#ffffff",
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              component="h1"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: 2,
              }}
            >
              Upload Certificate or URLs Courses
            </Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "repeat(2, 1fr)" },
                gap: 2,
              }}
            >
              {courseData?.courses?.map((course) => (
                <div key={course.course_id} style={{ marginBottom: "16px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!selectedCourses[course.course_id]}
                        onChange={() => handleCheckboxChange(course.course_id)}
                      />
                    }
                    label={course.course_name}
                  />

                  {selectedCourses[course.course_id] && (
                    <>
                      {course.status === "0" && courseData.status === 0 && (
                        <div className="flex items-center gap-4">
                          <CloudUpload
                            className="text-gray-500"
                            fontSize="large"
                          />
                          <div className="w-full">
                            <input
                              type="file"
                              required
                              onChange={(e) =>
                                handleFileChange(course.course_id, e)
                              }
                              className="w-full text-gray-500 font-medium text-base bg-gray-100 file:cursor-pointer cursor-pointer file:border-0 file:py-2.5 file:px-4 file:mr-4 file:bg-gray-800 file:hover:bg-gray-700 file:text-white rounded"
                            />
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ marginTop: 1 }}
                            >
                              Only PDF files are allowed for{" "}
                              {course.course_name}.
                            </Typography>
                          </div>
                        </div>
                      )}

                      {course.status === "1" && (
                        <div className="flex items-center gap-4">
                          <CheckCircle
                            className="text-green-500"
                            fontSize="large"
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              marginTop: 1,
                              color: "green",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            IN-PROGRESS.
                          </Typography>
                        </div>
                      )}

                      {course.status === "2" && (
                        <div className="flex items-center gap-4">
                          <Verified
                            className="text-blue-500"
                            fontSize="large"
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              marginTop: 1,
                              color: "blue",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            VERIFIED.
                          </Typography>
                        </div>
                      )}

                      {course.status === "3" && (
                        <div className="flex items-center gap-4">
                          <CircleX className="text-red-500" fontSize="large" />
                          <Typography
                            variant="body2"
                            sx={{
                              marginTop: 1,
                              color: "red",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            REJECTED.
                          </Typography>
                        </div>
                      )}

                      {courseData?.status === 1 && course.status === "0" && (
                        <TextField
                          label={`Enter URL for ${course.course_name}`}
                          fullWidth
                          required
                          onChange={(e) =>
                            handleTextChange(course.course_id, e)
                          }
                          sx={{ marginTop: 2 }}
                        />
                      )}
                    </>
                  )}
                </div>
              ))}
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="success"
              fullWidth
              disabled={uploading}
              sx={{
                marginTop: 3,
              }}
            >
              {uploading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </CardContent>
        </Card>
      )}

      {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        closeOnClick
        pauseOnHover
      /> */}
    </Box>
  );
};

const ErrorComponent = ({ error }) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <div className="flex items-center justify-center mb-6">
          <XCircle className="text-red-500 w-16 h-16" />
        </div>
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
          Oops! Something went wrong
        </h2>
        <p className="text-center text-gray-600 mb-6">
          We couldn't fetch the course data. Please try again later.
        </p>
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
          <p className="text-sm text-red-700">Error details: {error.message}</p>
        </div>
        <div className="flex justify-center">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300 ease-in-out"
            onClick={() => window.location.reload()}
          >
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
