import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
// import { Button } from "@mui/material";
// import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Select from "react-select";
// import { auth } from "../../firebase";
// import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import pp from "../../assets/imgs/pp.png";
import {
  profileGetDataService,
  getStates,
  getPrograms,
  getInstitutes,
  profileUpdateDataService,
} from "../../services/dataService";
import { toast } from "react-toastify";
// import { SignalCellularNullRounded } from "@mui/icons-material";
import { options } from "numeral";
const gender_options = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Transgender", label: "Transgender" },
];
const category_options = [
  { value: "OBC", label: "OBC" },
  { value: "SC", label: "SC" },
  { value: "ST", label: "ST" },
  { value: "GEN", label: "General" },
  //{ value: "NT", label: "NT" },
  { value: "EWS", label: "EWS" },
];
const annualIncome_options = [
  { value: "0", label: "> Below 2 lakh" },
  { value: "1", label: "< Above 2 lakh" },
];
const semester_options = [
  { value: "1st", label: "1st" },
  { value: "2nd", label: "2nd" },
  { value: "3rd", label: "3rd" },
  { value: "4th", label: "4th" },
  { value: "5th", label: "5th" },
  { value: "6th", label: "6th" },
  { value: "7th", label: "7th" },
  { value: "8th", label: "8th" },
  { value: "9th", label: "9th" },
  { value: "10th", label: "10th" },
];

const Profile = () => {
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [showBranchField, setShowBranchField] = useState(false);
  // const [hasFilled, setHasFilled] = useState(false);
  // const [otp, setOtp] = useState("");
  // const [verified, setVerified] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);
  const [instituteOptions, setInstituteOptions] = useState([]);
  const [instituteData, setInstituteData] = useState({});
  const navigateTo = useNavigate();
  function handleSuccessMessage(message) {
    toast.success(message, {
      autoClose: 4000,
      position: "top-center",
    });
  }
  const fetchStatesData = async () => {
    try {
      const response = await getStates.statesData(); // Replace with actual API call
      console.log("state", response?.data);
      if (response && response.data) {
        options = response?.data?.map((state) => ({
          // value: state.state_name.toLowerCase().replace(/\s+/g, "-"),
          value: state.state_id,
          label: state.state_name,
        }));
        setStateOptions(options);
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };
  const fetchProgramsData = async () => {
    try {
      const response = await getPrograms.programsData(); // Replace with actual API call
      // console.log(response?.data);
      if (response && response.data) {
        options = response?.data?.map((state) => ({
          // value: state.state_name.toLowerCase().replace(/\s+/g, "-"),
          value: state.program_id,
          label: state.program_name,
          branches: state.branches,
          duration: state.duration,
        }));
        setProgramOptions(options);
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };
  const fetchInstitutesData = async () => {
    try {
      const response = await getInstitutes.institutesData(); // Replace with actual API call
      console.log(response?.data);
      if (response && response.data) {
        options = response?.data?.map((state) => ({
          // value: state.state_name.toLowerCase().replace(/\s+/g, "-"),
          value: state.institute_id,
          label: state.institute_name,
          state_id: state.state_id,
          state_name: state.state_name,
        }));
        setInstituteOptions(options);
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    profile: "",
    mobile: "",
    email: "",
    aicteId: "",
    dob: "",
    caste: "", //modified
    state_id: "",
    gender: "", //modified
    address: "",
    tenth_mark: "",
    matric_passout_year: "", //added
    is_diploma: "",
    twth_mark: "",
    intermediate_passout_year: "", //added
    program_id: 0,
    branch: "",
    year_of_addmission: "", //added
    semister: "",
    btech_cgpa: "",
    final_year: "", //year_of_addmission+current semister
    institute_id: "",
    roll_no: "",
    regd_no: "",
    is_status: "", //added
    family_income: "",
  });
  // const inputRef = useRef(null);

  const [errors, setErrors] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    profile: "",
    mobile: "",
    email: "",
    aicteId: "",
    dob: "",
    caste: "",
    state_id: "",
    gender: "",
    address: "",
    tenth_mark: "",
    matric_passout_year: "", //added
    is_diploma: "",
    diploma: "",
    twth_mark: "",
    intermediate_passout_year: "", //added
    program_id: "",
    branch: "",
    year_of_addmission: "", //added
    semister: "",
    btech_cgpa: "",
    final_year: "", //added
    institute_id: "",
    roll_no: "",
    regd_no: "",
    is_status: "", //added
    family_income: "",
  });
  const nameRegex = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[1-9][0-9]{9}$/;
  const aicteRegex = /^STU[0-9a-zA-Z]{13,}$/;
  const cgpaRegex = /^(?:[0-9](?:\.[0-9]{1,2})?)$/;
  const percentageRegex = /^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)%?$/;
  const middleNameRegex = /^[a-zA-Z]*(\s|\.)?[a-zA-Z]*$/;

  useEffect(() => {
    const savedFormData = localStorage.getItem("updateProfileFormData");
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target || e;

    // Check if the input field is 'is_diploma'
    if (name === "is_diploma") {
      // Call setCompletedDiplomaHandler instead of directly updating the state
      setCompletedDiplomaHandler(value);
    } else {
      // setFormData({ ...formData, is_almuni: value });
      let updatedValue = value;

      // Additional validation and formatting logic as per your requirements
      if (name === "aicteId" && value.length >= 3) {
        const capitalizedAicteId =
          value.slice(0, 3).toUpperCase() + value.slice(3);
        updatedValue = capitalizedAicteId;
      }

      // Update the form data state
      setFormData({
        ...formData,
        [name]: updatedValue,
      });
      console.log(formData);

      // Validate the input and set the error if necessary
      let error = "";
      switch (name) {
        case "email":
          error = emailRegex.test(value) ? "" : "Invalid email format";
          break;
        case "mobile":
          error = phoneRegex.test(value) ? "" : "Invalid phone number";
          break;
        case "aicteId":
          error = aicteRegex.test(value) ? "" : "Invalid AICTE ID format";
          break;
        case "btech_cgpa":
          error = cgpaRegex.test(value) ? "" : "Invalid CGPA";
          break;
        case "tenth_mark":
          error = percentageRegex.test(value) ? "" : "Invalid Percentage";
          break;
        case "middle_name":
          error = middleNameRegex.test(value) ? "" : "Invalid middle Name";
          break;
        case "first_name":
          error = nameRegex.test(value) ? "" : "Invalid first Name";
          break;
        case "last_name":
          error = nameRegex.test(value) ? "" : "Invalid last Name";
          break;
        case "twth_mark":
          error = percentageRegex.test(value) ? "" : "Invalid Percentage";
          break;
        default:
          break;
      }

      // Set the error state
      setErrors({
        ...errors,
        [name]: error,
      });
    }

    // Save form data to localStorage
    localStorage.setItem("updateProfileFormData", JSON.stringify(formData));
  };
  //
  const calculatePassoutYear = () => {
    const duration = programOptions?.filter(
      (program) => program?.program_id === selectedProgram?.program_id
    )[0]?.duration;
    if (duration && formData.year_of_addmission) {
      const admissionYear = new Date(formData.year_of_addmission).getFullYear();
      const passoutYearValue = admissionYear + duration;
      const currentYear = new Date().getFullYear();
      if (currentYear > passoutYearValue) {
        setFormData((prevFormData) => ({ ...prevFormData, is_status: "1" }));
      } else
        setFormData((prevFormData) => ({ ...prevFormData, is_status: "0" }));
      setFormData((prevState) => ({
        ...prevState,
        final_year: passoutYearValue.toString(),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        final_year: "",
      }));
    }
  };
  const selectedInstitutes = () => {
    console.log(instituteData, "chc");
    if (instituteData) {
      setFormData((prevData) => ({
        ...prevData,
        institute_id: formData?.institute_id,
        state_id: formData?.state_id,
        
      }));
    }
  };
  useEffect(() => {
    selectedInstitutes();
    if (formData.year_of_addmission && selectedProgram?.value) {
      calculatePassoutYear();
    }
  }, [formData?.year_of_addmission, selectedProgram, instituteData?.state_id]);
  //................ for profile image
  // const handleImageClick = () => {
  //   inputRef.current.click();
  // };
  // //................
  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     // Clear previous error message
  //     setErrors({ ...errors, profile: "" });
  //     setFormData({
  //       ...formData,
  //       profile: URL.createObjectURL(file), // Set profile image preview
  //     });
  //   }
  // };
  //................ for mobile no verify
  // const generateRecaptcha = () => {
  //   window.recaptchaVerifier = new RecaptchaVerifier(
  //     "recaptcha",
  //     {
  //       size: "invisible",
  //       callback: () => {
  //         // reCAPTCHA solved, allow signInWithPhoneNumber.
  //       },
  //     },
  //     auth
  //   );
  // };

  // const handleSend = (event) => {
  //   event.preventDefault();
  //   setHasFilled(true);
  //   // console.log(formData.phone);
  //   generateRecaptcha();
  //   let appVerifier = window.recaptchaVerifier;
  //   signInWithPhoneNumber(auth, formData.phone, appVerifier)
  //     .then((confirmationResult) => {
  //       // SMS sent.
  //       window.confirmationResult = confirmationResult;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const verifyOtp = (event) => {
  //   let enteredOtp = event.target.value;
  //   setOtp(enteredOtp);
  // };

  // const handleVerify = () => {
  //   let confirmationResult = window.confirmationResult;
  //   confirmationResult
  //     .confirm(otp)
  //     .then(() => {
  //       setVerified(true);
  //       const formattedPhone = formData.phone.replace("+91", "");
  //       setFormData({
  //         ...formData,
  //         phone: formattedPhone,
  //       });
  //       // alert("Phone number verified!");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       alert("Invalid OTP. Please try again.");
  //     });
  // };
  //.............

  const setCompletedDiplomaHandler = (value) => {
    let intermediateValue = "";

    if (value === "1") {
      intermediateValue = formData.twth_mark;
    } else {
      intermediateValue = formData.twth_mark;
    }

    setFormData({
      ...formData,
      is_diploma: value,
      twth_mark: intermediateValue,
    });
  };
  const setProgramHandler = (selectedOption) => {
    setSelectedProgram(selectedOption);
    console.log(selectedOption, "branches");
    setShowBranchField(selectedOption?.branches?.length);
    setFormData({
      ...formData,
      program_id: selectedOption ? selectedOption.value : "",
      branch: selectedOption ? selectedOption.value : "",
    });
  };
  // console.log(formData);
  //..........select passout year
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  //matric passout year select
  const matricPassoutYearOptions = [];
  const currentYear = getCurrentYear();
  for (let year = currentYear - 9; year <= currentYear; year++) {
    matricPassoutYearOptions.push({
      value: year.toString(),
      label: year.toString(),
    });
  }
  // Generate an array of options from the current year to current year + 10
  const passoutYearOptions = [];
  for (let year = getCurrentYear(); year <= getCurrentYear() + 3; year++) {
    passoutYearOptions.push({ value: year.toString(), label: year.toString() });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for empty fields and set errors
    const newErrors = {
      first_name: formData?.first_name?.trim() ? "" : "This field is required",
      middle_name: formData?.middle_name
        ? ""
        : "Middle name is required. Please enter your middle name or use a dot if you don't have one.",
      last_name: formData?.last_name?.trim() ? "" : "This field is required",
      // profile: formData.profile.trim() ? "" : "This field is required",
      phone: phoneRegex.test(formData?.mobile) ? "" : "Invalid Phone No",
      // phone:
      //   !phoneRegex.test(formData.phone) && !verified
      //     ? "Invalid Phone No,please varify"
      //     : "",
      email: emailRegex.test(formData.email) ? "" : "Invalid email format",
      aicteId: aicteRegex.test(formData.aicteId)
        ? ""
        : "Invalid AICTE ID format",
      dob: formData?.dob?.trim() ? "" : "This field is required",
      caste: formData?.caste?.trim() ? "" : "This field is required",
      gender: formData?.gender?.trim() ? "" : "This field is required",
      state_id:
        formData?.state_id !== "" && formData.state_id !== null
          ? ""
          : "This field is required",
      address: formData?.address?.trim() ? "" : "This field is required",
      tenth_mark: formData?.tenth_mark?.trim() ? "" : "This field is required",
      matric_passout_year: formData?.matric_passout_year?.trim()
        ? ""
        : "This field is required",
      is_diploma: formData?.is_diploma?.trim() ? "" : "This field is required",
      twth_mark:
        !formData?.twth_mark?.trim() &&
        (formData?.is_diploma === "1" || formData?.is_diploma === "0")
          ? "intermediate Percentage is required"
          : "",
      intermediate_passout_year: formData?.intermediate_passout_year?.trim()
        ? ""
        : "This field is required",
      program_id:
        formData?.program_id !== undefined && formData.program_id !== null
          ? ""
          : "This field is required",
      branch: formData?.branch?.trim() ? "" : "This field is required",
      year_of_addmission: formData?.year_of_addmission?.trim()
        ? ""
        : "This field is required",
      semister: formData?.semister?.trim() ? "" : "This field is required",
      btech_cgpa: formData?.btech_cgpa?.trim() ? "" : "This field is required",
      final_year: formData?.final_year?.trim() ? "" : "This field is required",
      institute_id:
        formData?.institute_id !== "" && formData.institute_id !== null
          ? ""
          : "This field is required",
      roll_no: formData?.roll_no?.trim() ? "" : "This field is required",
      regd_no: formData?.regd_no?.trim() ? "" : "This field is required",
      is_status: formData?.is_status?.trim() ? "" : "This field is required",
      family_income: formData?.family_income?.trim()
        ? ""
        : "This field is required",
    };
    setErrors(newErrors);
    // if (!verified) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     phone: "Phone number is not verified",
    //   }));
    //   return;
    // }

    // Check if any errors exist
    const hasErrors = Object.values(newErrors).some((error) => error !== "");

    if (!hasErrors) {
      // console.log("Submitting...");
      // console.log(formData);
      // Perform additional actions if the form is valid
      try {
        const response = await profileUpdateDataService.updateProfile(formData);
        // console.log(response?.data?.detail);
        handleSuccessMessage(response?.data?.detail);
        if (response && response.status === 200) {
           navigateTo("/domain-choose");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Form has errors. Please fix them.");
    }
    localStorage.removeItem("updateProfileFormData");
  };
  useEffect(() => {
    const storedData = localStorage.getItem("updateProfileFormData");
    if (storedData) {
      setFormData(JSON.parse(storedData));
    }
    fetchInitialData(); // Move this call here to avoid the error
    fetchStatesData();
    fetchProgramsData();
    fetchInstitutesData();
  }, []);

  // const fetchInitialData = () => {
  //   // Simulate API call or use actual API call
  //   // Set initial data here
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     email: "nitya321@gmail.com",
  //     institute_name: "Biju Patnaik University of Technology Odisha,Rourkela",
  //   }));
  // };
  //..............
  const convertToISOFormat = (date) => {
    if (!date || typeof date !== "string") {
      throw new Error("Invalid date format");
    }

    // Regular expression to check if the date is in MM/DD/YYYY format
    const mmddyyyyPattern = /^\d{2}\/\d{2}\/\d{4}$/;

    // Regular expression to check if the date is in YYYY-MM-DD format
    const yyyymmddPattern = /^\d{4}-\d{2}-\d{2}$/;

    if (mmddyyyyPattern.test(date)) {
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    } else if (yyyymmddPattern.test(date)) {
      return date; // Already in YYYY-MM-DD format
    } else {
      throw new Error("Date should be in MM/DD/YYYY or YYYY-MM-DD format");
    }
  };

  const fetchInitialData = async () => {
    try {
      const response = await profileGetDataService.profileData(); // Replace with actual API call
      console.log(response?.data, "initialData");
      if (response && response.data) {
        setFormData({
          first_name: response?.data?.first_name,
          middle_name: response?.data?.middle_name,
          last_name: response?.data?.last_name,
          aicteId: response?.data?.aicte_id,
          email: response?.data?.email,
          caste: response?.data?.caste,
          state_id: response?.data?.state_id,
          gender: response?.data?.gender,
          address: response?.data?.address,
          dob: convertToISOFormat(response?.data?.dob),
          mobile: response?.data?.mobile,
          tenth_mark: response?.data?.tenth_mark,
          roll_no: response?.data?.roll_no,
          institute_id: response?.data?.institute_id,
        });
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  //..............
  return (
    <div className="m-5 min-h-[620px] max-h-auto rounded-md shadow-md p-6">
      <h2 className="text-lg font-bold text-center mb-5">Update Profile</h2>
      <form>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="first_name"
              className="block text-sm font-semibold text-gray-700"
            >
              First Name:
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              required
              placeholder="Your First Name"
              className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.first_name ? "border-red-500" : ""
              }`}
              value={formData.first_name}
              onChange={handleInputChange}
            />
            {errors.first_name && (
              <p className="text-red-500 text-sm mt-1">{errors.first_name}</p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="middle_name"
              className="block text-sm font-semibold text-gray-600"
            >
              Middle Name:
            </label>
            <input
              type="text"
              id="middle_name"
              name="middle_name"
              required
              placeholder="Your Middle Name"
              className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.middle_name ? "border-red-500" : ""
              }`}
              value={formData.middle_name}
              onChange={handleInputChange}
            />
            {errors.middle_name && (
              <p className="text-red-500 text-sm mt-1">{errors.middle_name}</p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="last_name"
              className="block text-sm font-semibold text-gray-600"
            >
              Last Name:
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              required
              placeholder="Your Last Name"
              className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.last_name ? "border-red-500" : ""
              }`}
              value={formData.last_name}
              onChange={handleInputChange}
            />
            {errors.last_name && (
              <p className="text-red-500 text-sm mt-1">{errors.last_name}</p>
            )}
          </div>
          {/* <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <div className=" md:ml-10">
              {formData.profile ? (
                <img
                  src={formData.profile}
                  className="h-20 w-20 rounded-full"
                  alt="profile"
                />
              ) : (
                <img
                  src={pp}
                  alt="profile"
                  className="h-20 w-20 rounded-full"
                />
              )}
              <input
                type="file"
                name="profile"
                accept="image/*"
                onChange={handleImageChange}
                ref={inputRef}
                required
                className="ml-4 hidden"
              />
              <Button
                variant="contained"
                startIcon={<CameraAltIcon />}
                color="info"
                onClick={handleImageClick}
                size="small"
                sx={{ marginTop: 1 }}
              >
                upload
              </Button>
            </div>
            {errors.profile && (
              <p className="text-red-500 text-sm mt-1">{errors.profile}</p>
            )}
          </div> */}
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="email"
              className="block text-sm font-semibold text-gray-600"
            >
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              placeholder="Your Email id"
              className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.email ? "border-red-500" : ""
              }`}
              value={formData.email}
              onChange={handleInputChange}
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email}</p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="phone"
              className="block text-sm font-semibold text-gray-600"
            >
              Phone No.:
            </label>
            <div className="relative">
              <input
                type="tel"
                id="phone"
                name="mobile"
                required
                placeholder="Your Phone No"
                className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                  errors.mobile ? "border-red-500" : ""
                }`}
                value={formData.mobile}
                onChange={handleInputChange}
                // disabled={verified}
              />
              {/* {!verified && formData?.phone?.length === 13 && (
                <button
                  onClick={handleSend}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-sky-400 px-2 rounded-full text-white"
                >
                  {verified ? "Verified" : "Verify"}
                </button>
              )}
              {verified && (
                <div className="absolute inset-y-0 right-2 flex items-center">
                  <span className=" text-white bg-sky-400 px-2 rounded-full">
                    Verified
                  </span>
                </div>
              )} */}
            </div>

            {errors.mobile && (
              <p className="text-red-500 text-sm mt-1">{errors.mobile}</p>
            )}

            {/* Input field for OTP */}
            {/* {hasFilled && !verified && (
              <div className="mt-4">
                <label htmlFor="otp" className="block mb-1">
                  Enter OTP:
                </label>
                <input
                  type="text"
                  id="otp"
                  name="otp"
                  value={otp}
                  onChange={verifyOtp}
                  className="border-2 text-gray-950 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400"
                />
                <button
                  onClick={handleVerify}
                  className="mt-2 bg-sky-400 px-4 py-2 rounded-md text-white"
                >
                  Verify
                </button>
              </div>
            )} */}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="aicte_id"
              className="block text-sm font-semibold text-gray-600"
            >
              Aicte ID:
            </label>
            <input
              type="text"
              id="aicte_id"
              name="aicteId"
              required
              placeholder="aicte id"
              className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.aicteId ? "border-red-500" : ""
              }`}
              value={formData.aicteId}
              onChange={handleInputChange}
            />
            {errors.aicteId && (
              <p className="text-red-500 text-sm mt-1">{errors.aicteId}</p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="dob"
              className="block text-sm font-semibold text-gray-600"
            >
              Date of Birth:
            </label>
            <input
              type="date"
              id="dob"
              name="dob"
              required
              placeholder="enter Your DOB"
              className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.dob ? "border-red-500" : ""
              }`}
              value={formData.dob}
              onChange={handleInputChange}
            />
            {errors.dob && (
              <p className="text-red-500 text-sm mt-1">{errors.dob}</p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="gender"
              className="block text-sm font-semibold text-gray-600"
            >
              gender:
            </label>
            <Select
              id="gender"
              name="gender"
              value={gender_options.find(
                (option) => option.value === formData.gender
              )}
              onChange={(selectedOption) =>
                handleInputChange({
                  name: "gender",
                  value: selectedOption.value,
                })
              }
              options={gender_options}
              className={`border-2 text-gray-950 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.gender ? "border-red-500" : ""
              }`}
            />
            {errors.gender && (
              <p className="text-red-500 text-sm mt-1">{errors.gender}</p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="caste"
              className="block text-sm font-semibold text-gray-600"
            >
              category:
            </label>
            <Select
              id="caste"
              name="caste"
              value={category_options.find(
                (option) => option.value === formData.caste
              )}
              onChange={(selectedOption) =>
                handleInputChange({
                  name: "caste",
                  value: selectedOption.value,
                })
              }
              options={category_options}
              className={`border-2 text-gray-950 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.caste ? "border-red-500" : ""
              }`}
            />
            {errors.caste && (
              <p className="text-red-500 text-sm mt-1">{errors.caste}</p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="institute"
              className="block text-sm font-semibold text-gray-600"
            >
              Institute Name:
            </label>
            {/* <Select
              id="institute"
              name="institute_id"
              value={instituteOptions.find(
                (option) => option.value === formData.institute_id
              )} // Match value with the selected option
              onChange={(selectedOption) => {
                setFormData({
                  ...formData,
                  institute_id: selectedOption.value, // Update formData with the selected value
                });
                const selectedInstitute = instituteOptions.find(
                  (option) => option.value === selectedOption.value
                );
                setInstituteData(selectedInstitute);
              }}
              options={instituteOptions}
              className={`border-2 text-gray-950 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.institute_id ? "border-red-500" : ""
              }`}
            /> */}

            <input
              type="text"
              id="institute"
              name="institute_id"
              required
              readOnly
              placeholder="Your Institute Name"
              className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.institute_id ? "border-red-500" : ""
              }`}
              value={formData.institute_id}
              onChange={handleInputChange}
            />

            {/* {errors.institute_id && (
              <p className="text-red-500 text-sm mt-1">{errors.institute_id}</p>
            )} */}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="state"
              className="block text-sm font-semibold text-gray-600"
            >
              State:
            </label>
            {/* <input
              type="text"
              id="state"
              name="state_id"
              readOnly
              value={instituteData?.state_name}
              className={`border-2 p-2 text-gray-950 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.state_id ? "border-red-500" : ""
              }`}
            /> */}
            <input
              type="text"
              id="state"
              name="state_id"
              required
              readOnly
              placeholder="Your Institute Name"
              className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.state_id ? "border-red-500" : ""
              }`}
              value={formData.state_id}
              onChange={handleInputChange}
            />
            {/* {errors.state_id && (
              <p className="text-red-500 text-sm mt-1">{errors.state_id}</p>
            )} */}
          </div>

          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="city"
              className="block text-sm font-semibold text-gray-600"
            >
              City:
            </label>
            <input
              type="text"
              id="city"
              name="address"
              required
              placeholder="Your your city"
              className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.address ? "border-red-500" : ""
              }`}
              value={formData.address}
              onChange={handleInputChange}
            />
            {errors.address && (
              <p className="text-red-500 text-sm mt-1">{errors.address}</p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="tenth_mark"
              className="block text-sm font-semibold text-gray-600"
            >
              10th Mark(%):
            </label>
            <input
              type="number"
              id="tenth_mark"
              name="tenth_mark"
              required
              placeholder="Your your 10th mark in %"
              className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.tenth_mark ? "border-red-500" : ""
              }`}
              value={formData.tenth_mark}
              onChange={handleInputChange}
            />
            {errors.tenth_mark && (
              <p className="text-red-500 text-sm mt-1">{errors.tenth_mark}</p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="matric_passout_year"
              className="block text-sm font-semibold text-gray-600"
            >
              10th Passout year:
            </label>
            <Select
              id="matric_passout_year"
              name="matric_passout_year"
              value={matricPassoutYearOptions.find(
                (option) => option.value === formData.matric_passout_year
              )}
              onChange={(selectedOption) =>
                handleInputChange({
                  name: "matric_passout_year",
                  value: selectedOption.value,
                })
              }
              options={matricPassoutYearOptions}
              className={`border-2 text-gray-950 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.matric_passout_year ? "border-red-500" : ""
              }`}
            />
            {errors.matric_passout_year && (
              <p className="text-red-500 text-sm mt-1">
                {errors.matric_passout_year}
              </p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label className="block text-sm font-semibold text-gray-600">
              Completed Diploma:
            </label>
            <div className="flex items-center mt-2">
              <label className="inline-flex items-center mr-4">
                <input
                  type="radio"
                  value="1"
                  checked={formData.is_diploma === "1"}
                  onChange={() => setCompletedDiplomaHandler("1")}
                />
                <span className="ml-2">Yes</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="0"
                  checked={formData.is_diploma === "0"}
                  onChange={() => setCompletedDiplomaHandler("0")}
                />
                <span className="ml-2">No</span>
              </label>
            </div>
            {errors.is_diploma && (
              <p className="text-red-500 text-sm mt-1">{errors.is_diploma}</p>
            )}
          </div>
          {formData.is_diploma === "1" && (
            <div className="col-span-2 md:col-span-1 lg:col-span-1">
              <label
                htmlFor="percentage"
                className="block text-sm font-semibold text-gray-600"
              >
                Diploma Percentage:
              </label>
              <input
                type="text"
                id="diploma"
                name="twth_mark" // Adjusted name to match formData
                placeholder="Your Diploma Percentage"
                className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                  errors.twth_mark ? "border-red-500" : ""
                }`}
                value={formData.twth_mark} // Adjusted value to match formData
                onChange={handleInputChange}
              />
              {errors.twth_mark && (
                <p className="text-red-500 text-sm mt-1">{errors.twth_mark}</p>
              )}
            </div>
          )}
          {formData.is_diploma === "0" && (
            <div className="col-span-2 md:col-span-1 lg:col-span-1">
              <label
                htmlFor="twth_mark"
                className="block text-sm font-semibold text-gray-600"
              >
                12th Percentage:
              </label>
              <input
                type="text"
                id="twth_mark"
                name="twth_mark"
                placeholder="Your 12th Percentage"
                className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                  errors.twth_mark ? "border-red-500" : ""
                }`}
                value={formData.twth_mark}
                onChange={handleInputChange}
              />
              {errors.twth_mark && (
                <p className="text-red-500 text-sm mt-1">{errors.twth_mark}</p>
              )}
            </div>
          )}
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="intermediate_passout_year"
              className="block text-sm font-semibold text-gray-600"
            >
              12th/diploma Passout year:
            </label>
            <Select
              id="intermediate_passout_year"
              name="intermediate_passout_year"
              value={matricPassoutYearOptions.find(
                (option) => option.value === formData.intermediate_passout_year
              )}
              onChange={(selectedOption) =>
                handleInputChange({
                  name: "intermediate_passout_year",
                  value: selectedOption.value,
                })
              }
              options={matricPassoutYearOptions}
              className={`border-2 text-gray-950 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.intermediate_passout_year ? "border-red-500" : ""
              }`}
            />
            {errors.intermediate_passout_year && (
              <p className="text-red-500 text-sm mt-1">
                {errors.intermediate_passout_year}
              </p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label>Select Program:</label>
            <Select
              id="program"
              name="program_id"
              value={programOptions.find(
                (option) => option.value === formData.program_id
              )}
              onChange={setProgramHandler}
              options={programOptions}
              className={`border-2 text-gray-950 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.program_id ? "border-red-500" : ""
              }`}
            />
            {errors.program_id && (
              <p className="text-red-500 text-sm mt-1">{errors.program_id}</p>
            )}
          </div>
          {/* <div> */}
          {showBranchField && (
            <div className="col-span-2 md:col-span-1 lg:col-span-1">
              <label>Branch:</label>
              <Select
                id="branch"
                name="branch"
                value={selectedProgram.branches.find(
                  (branchOption) => branchOption.value === formData.branch
                )}
                onChange={(selectedOption) =>
                  handleInputChange({
                    name: "branch",
                    value: selectedOption.label,
                  })
                }
                options={
                  selectedProgram?.branches
                    ? selectedProgram.branches.map((branch) => ({
                        value: branch,
                        label: branch,
                      }))
                    : []
                }
              />
              {errors.branch && (
                <p className="text-red-500 text-sm mt-1">{errors.branch}</p>
              )}
            </div>
          )}
          {/* </div> */}
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="semister"
              className="block text-sm font-semibold text-gray-600"
            >
              Semester:
            </label>
            <Select
              id="semister"
              name="semister"
              value={semester_options.find(
                (option) => option.value === formData.semister
              )}
              onChange={(selectedOption) =>
                handleInputChange({
                  name: "semister",
                  value: selectedOption.value,
                })
              }
              options={semester_options}
              className={`border-2 text-gray-950 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.semister ? "border-red-500" : ""
              }`}
            />
            {errors.semister && (
              <p className="text-red-500 text-sm mt-1">{errors.semister}</p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="dob"
              className="block text-sm font-semibold text-gray-600"
            >
              Admission Date:
            </label>
            <input
              type="date"
              id="year_of_addmission"
              name="year_of_addmission"
              required
              placeholder="Admission date"
              className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.year_of_addmission ? "border-red-500" : ""
              }`}
              value={formData.year_of_addmission}
              onChange={handleInputChange}
            />
            {errors.year_of_addmission && (
              <p className="text-red-500 text-sm mt-1">
                {errors.year_of_addmission}
              </p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="cgpa"
              className="block text-sm font-semibold text-gray-600"
            >
              Cgpa:
            </label>
            <input
              type="number"
              id="cgpa"
              name="btech_cgpa"
              required
              placeholder="Your current Semester CGPA"
              className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.btech_cgpa ? "border-red-500" : ""
              }`}
              value={formData.btech_cgpa}
              onChange={handleInputChange}
            />
            {errors.btech_cgpa && (
              <p className="text-red-500 text-sm mt-1">{errors.btech_cgpa}</p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="passout_year"
              className="block text-sm font-semibold text-gray-600"
            >
              Passout year:
            </label>
            {/* <Select
              id="passout_year"
              name="passout_year"
              value={passoutYearOptions.find(
                (option) => option.value === formData.passout_year
              )}
              onChange={(selectedOption) =>
                handleInputChange({
                  name: "passout_year",
                  value: selectedOption.value,
                })
              }
              options={passoutYearOptions}
              className={`border-2 text-gray-950 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.passout_year ? "border-red-500" : ""
              }`}
            /> */}
            {/* <input
              type="text"
              id="passoutYear"
              value={formData.graduation_passout_year}
              readOnly
            /> */}
            <input
              type="text"
              id="passout_year"
              name="final_year"
              placeholder="year of passout"
              value={formData.final_year}
              readOnly
              className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.final_year ? "border-red-500" : ""
              }`}
            />
            {/* {errors.graduation_passout_year && (
              <p className="text-red-500 text-sm mt-1">
                {errors.graduation_passout_year}
              </p>
            )} */}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="roll_no"
              className="block text-sm font-semibold text-gray-600"
            >
              Roll No.:
            </label>
            <input
              type="text"
              id="roll_no"
              name="roll_no"
              required
              placeholder="Roll No"
              className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.roll_no ? "border-red-500" : ""
              }`}
              value={formData.roll_no}
              onChange={handleInputChange}
            />
            {errors.roll_no && (
              <p className="text-red-500 text-sm mt-1">{errors.roll_no}</p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="regd_no"
              className="block text-sm font-semibold text-gray-600"
            >
              university Regd. No.:
            </label>
            <input
              type="text"
              id="regd_no"
              name="regd_no"
              required
              placeholder="Regd No"
              className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.regd_no ? "border-red-500" : ""
              }`}
              value={formData.regd_no}
              onChange={handleInputChange}
            />
            {errors.regd_no && (
              <p className="text-red-500 text-sm mt-1">{errors.regd_no}</p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="is_status"
              className="block text-sm font-semibold text-gray-600"
            >
              is Alumni:
            </label>
            <div className="flex items-center mt-2">
              <label className="inline-flex items-center mr-4">
                <input
                  type="radio"
                  value={formData.is_status}
                  name="is_status"
                  checked={formData.is_status === "1"}
                  readOnly
                  // onChange={handleInputChange}
                />
                <span className="ml-2">Yes</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value={formData.is_status}
                  name="is_status"
                  checked={formData.is_status === "0"}
                  readOnly
                  // onChange={handleInputChange}
                />
                <span className="ml-2">No</span>
              </label>
            </div>
            {errors.is_status && (
              <p className="text-red-500 text-sm mt-1">{errors.is_status}</p>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 lg:col-span-1">
            <label
              htmlFor="family_annual_income"
              className="block text-sm font-semibold text-gray-600"
            >
              Annual Income:
            </label>
            <Select
              id="family_annual_income"
              name="family_income"
              value={annualIncome_options.find(
                (option) => option.value === formData.family_income
              )}
              onChange={(selectedOption) =>
                handleInputChange({
                  name: "family_income",
                  value: selectedOption.value,
                })
              }
              options={annualIncome_options}
              className={`border-2 text-gray-950 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                errors.family_income ? "border-red-500" : ""
              }`}
            />
            {errors.family_income && (
              <p className="text-red-500 text-sm mt-1">
                {errors.family_income}
              </p>
            )}
          </div>
        </div>
        <div className=" flex justify-center md:justify-end mt-5 md:mt-2">
          <button
            type="submit"
            className=" bg-sky-600 rounded-md px-1 py-2  text-white w-28"
            onClick={handleSubmit}
          >
            Update profile
          </button>
        </div>
      </form>
      <div id="recaptcha"></div>
    </div>
  );
};

export default Profile;
